.contact-section {

  .subtiteWithGap {
    @media all and (max-width: 1200px) {
      margin-top: 96px;
    }

    @media all and (max-width: 700px) {
      margin-top: 64px;
    }
  }

  .sectionSubtitle {

    @media all and (max-width: 700px) {
      padding-bottom: 16px;
    }
  }

  .titleGap {
    padding-top: 96px;
    @media all and (max-width: 1200px) {
      padding-top: 64px;
    }

    @media all and (max-width: 700px) {
      padding-top: 24px;
    }
  }

  .title {
    @media all and (max-width: 1366px) {
      font-size: 4rem;
    }

    @media all and (max-width: 700px) {
      font-size: 2.5rem;
    }
  }


  .hover_underline {

    &:hover {
      text-decoration: underline !important;
    }

    span {
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .shortTrail{
    display: inline-flex;
  }
}