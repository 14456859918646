footer {
  &.hide {
    transition-duration: 0.3s;
    opacity: 0;
  }

  &.show {
    transition-duration: 0.3s;
    opacity: 1;
  }

  .container {
    border-top: 3px solid black;
    padding-bottom: 48px;
    padding-top: 48px;

    @media all and (max-width: 700px) {
      padding-bottom: 24px;
      padding-top: 24px;
    }

    .col {
      font-family: "nocturne-serif", serif;
      line-height: 1.2;
      font-size: 5.1rem;

      @media all and (max-width: 1366px) {
        font-size: 3.6rem;

        &.onMobileToRight {
          text-align: right;
        }
      }

      @media all and (max-width: 700px) {
        font-size: 2.5rem;

        &.onMobileToRight {
          text-align: right;
        }
      }

      &.toRight {
        text-align: right;
      }
    }
  }
}