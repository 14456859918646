.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1652px;
  margin: auto;
  background-position: center center;
  background-repeat: repeat-y;

  @media all and (max-width: 1920px) {
    margin-right: 64px;
    margin-left: 64px;
    width: calc(100% - 128px);
  }

  @media all and (max-width: 1200px) {
    margin-right: 48px;
    margin-left: 48px;
    width: calc(100% - 96px);
  }

  @media all and (max-width: 700px) {
    margin-right: 32px;
    margin-left: 32px;
    width: calc(100% - 64px);
  }

  &.isRaw {
    padding: 0px;
  }
}