.nextProject_section {
  padding-top: 128px;
  padding-bottom: 256px;

  @media all and (max-width: 1366px) {
    padding-top: 64px;
    padding-bottom: 128px;
  }

  @media all and (max-width: 700px) {
    padding-top: 64px;
    padding-bottom: 128px;
  }

  .nextProject-wrapper {
    transform: translateY(64px);
    transition-duration: 1s;
    opacity: 0;

    a {
      font-size: 23.5rem !important;

      @media all and (max-width: 1700px) {
        font-size: 16rem !important;
      }

      @media all and (max-width: 1366px) {
        font-size: 12rem !important;
      }

      @media all and (max-width: 992px) {
        font-size: 8rem !important;
      }

      @media all and (max-width: 700px) {
        font-size: 5.1rem !important;
      }

    }

    &.isOpen {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}