.portfolio_section {
  padding-top: 25vh;
  padding-bottom: 25vh;

  .subtitle,
  .title {
    transition-duration: 0.2s;
    letter-spacing: 1px;

  }

  .portfolio-wrapper {
    transform: scaleY(0);
    transition-duration: 1s;
    opacity: 0;

    &.isOpen {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  .row {
    align-items: center;
    padding-top: 64px;
    padding-bottom: 64px;

    @media all and (max-width: 1366px) {
      padding-bottom: 48px;
      padding-top: 48px;
    }

    @media all and (max-width: 700px) {
      padding-bottom: 32px;
      padding-top: 32px;
    }

    &.noPadding {
      @media all and (max-width: 1366px) {
        padding-bottom: 16px;
        padding-top: 0;
      }
    }

    &.portfolio-block {
      cursor: pointer;
      transform: translateX(1.5px);
      width: calc(100% - 3px);
      transition-duration: 0.2s;


      &:hover {
        // background: #EFEFEF;

        .title {
          color: transparent;
          // text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
          -webkit-text-stroke: 1px black;
          letter-spacing: 3px;
        }

        .subtitle {
          // letter-spacing: 3px;
          transform: scale(1.25) translateX(12.5%);
          color: white;
        }
      }
    }
  }

  hr {
    height: 3px;
    border-width: 0px;
    border-bottom: 3px solid black;
  }

}


.portfolio-img-wrapper {
  width: 500px;
  height: 700px;
  background: #EFEFEF;
  padding: 64px 8px;
  opacity: 0;
  transition-duration: 0.7s;
  transform: scale(0.65, 0.75);

  &.isActive {
    padding: 0px;
    opacity: 1;
    transform: scale(1);
  }

  .portfolio-prev-img,
  .portfolio-img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }

  .portfolio-img {
    border: 12px solid white;
    background-color: white;
  }

  .portfolio-prev-img.swapTime {
    opacity: 0;
    transition-duration: 0.3s;
  }
}