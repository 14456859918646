.facts_section {
  padding-top: 25vh;
  padding-bottom: 25vh;

  .swiper-slide,
  .title {
    cursor: -webkit-grab;

    @media all and (max-width: 700px) {
      font-size: 3.4rem;
    }
  }

  .titleWidthIndicator {
    display: flex;

    .sectionSubtitle {
      width: 100%;
    }

    .swiper-pagination-fraction-customWrapper {
      text-align: right;
    }
  }

  .swiper-pagination-fraction {
    display: none;
  }
}