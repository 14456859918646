@keyframes slideLeftOnLoad {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0vw);
  }
}

.hero_section {
  padding-top: 20vh;
  padding-bottom: 20vh;

  @media all and (max-width: 1366px) {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  @media all and (max-width: 700px) {
    padding-top: 96px;
    padding-bottom: 32px;
  }

  .info-cols-block {
    padding-top: 64px;

    @media all and (max-width: 1366px) {
      padding-top: 48px;
    }

    @media all and (max-width: 700px) {
      padding-top: 32px;
    }

    
    .info-col {
      opacity: 0;
      transition-duration: 0.8s;
      transform: translateY(64px);
      transition-delay: 0s;
      margin-top: 64px;
      margin-bottom: 32px;
      @media all and (max-width: 1366px) {
        margin-top: 48px;
      }
  
      @media all and (max-width: 700px) {
        margin-top: 24px;
      }
    }

    &.isActive {
      .info-col {
        opacity: 1;
        transform: translateY(0px);

        &:nth-child(1) {
          transition-delay: 0.6s;
        }

        &:nth-child(2) {
          transition-delay: 0.9s;
        }

        &:nth-child(3) {
          transition-delay: 1.2s;
        }

        &:nth-child(4) {
          transition-delay: 1.5s;
        }

        &:nth-child(5) {
          transition-delay: 1.8s;
        }

        &:nth-child(6) {
          transition-delay: 2.1s;
        }
      }
    }

    p {
      font-family: "adrianna-extended", sans-serif;
      font-size: 2.1rem;
      font-weight: 400;
      font-weight: normal;
      letter-spacing: 0.01em;
      line-height: 1.55;

      @media all and (max-width: 1600px) {
        font-size: 1.8rem;
      }

      @media all and (max-width: 1366px) {
        font-size: 1.4rem;
      }

      @media all and (max-width: 700px) {
        letter-spacing: 0.025em;
        font-size: 1rem;
      }


      strong {
        font-weight: bold;
      }

      a {
        position: relative;
        text-decoration: underline;
      }
    }
  }

  .description {}
}