.detectable-section {

  &.hide {
    transition-duration: 0.3s;
    opacity: 0;
  }

  &.show {
    transition-duration: 0.3s;
    opacity: 1;
  }
}