.home-page {

  section {
    padding-top: 30vh;
    padding-bottom: 30vh;

    @media all and (max-width: 700px) {
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }
}