.navbar {
  width: 100%;
  max-width: 1652px;
  margin: auto;

  .container {
    border-bottom: 3px solid black;
    padding-bottom: 16px;
    padding-top: 64px;

    @media all and (max-width: 1366px) {
      padding-top: 48px;
    }

    @media all and (max-width: 700px) {
      padding-top: 32px;
    }

    .col {
      font-family: "adrianna-extended", sans-serif;
      font-size: 2.1rem;
      font-weight: 600;
      letter-spacing: 0.03em;

      @media all and (max-width: 1600px) {
        font-size: 1.8rem;
      }

      @media all and (max-width: 1366px) {
        font-size: 1.4rem;
        letter-spacing: 0.01em;
      }

      @media all and (max-width: 700px) {
        letter-spacing: 0.02em;
        font-size: 0.8rem;
      }

      &.toRight {
        text-align: right;
      }

      &.langSwitch {
        span {
          cursor: pointer;
          user-select: none;
          display: inline-block;
          padding: 0px 3px;
        }

        .isActive {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            width: calc(100% - 15px);
            bottom: 0px;
            height: 2px;
            background: black;
            left: 7px;
          }
        }
      }
    }
  }
}