.page-wrapper {
  width: 100%;
  max-width: 1652px;
  margin: auto;

  .pageWrapper__section {
    margin-top: 32px;
    padding-top: 64px;
    padding-bottom: 64px;

    @media all and (max-width: 1200px) {
      padding-bottom: 32px;
    }
  }
}