.image_section {
  padding-top: 64px;
  padding-bottom: 64px;
  @media all and (max-width: 1366px) {
    padding-bottom: 48px;
    padding-top: 48px;
  }

  @media all and (max-width: 700px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  
  .image-wrapper {
    transform: translateY(64px);
    transition-duration: 1s;
    opacity: 0;

    img {
      width: 100%;
      height: auto;
    }

    video{
      width: 100%;
      height: auto;
    }

    &.isOpen {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}