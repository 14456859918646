.tailWrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  transition-duration: 0s;
  transform: translateY(-48px);
  width: 100%;

  .tail {
    transition-duration: 0s;
    display: inline-block;
    transform: translateY(110%);
  }

  &.isOpen {
    transition-duration: 1s;
    opacity: 1;
    transform: translateY(0px);

    .tail {
      transition-duration: 1s;
      transform: translateY(0%);
    }
  }
}