.standard-block {
  font-size: 2.1rem;
  line-height: 1;

  .title {
    font-family: "nocturne-serif", serif;
    font-size: 10rem;
    line-height: 1.2;

    @media all and (max-width: 1600px) {
      font-size: 8rem;
    }

    @media all and (max-width: 1366px) {
      font-size: 6rem;
    }

    @media all and (max-width: 700px) {
      font-size: 4.4rem;
    }

    @media all and (max-width: 400px) {
      font-size: 3.6rem;
    }
  }

  .sectionSubtitle {
    padding-bottom: 64px;

    @media all and (max-width: 1366px) {
      padding-bottom: 48px;
    }

    @media all and (max-width: 700px) {
      padding-bottom: 32px;
    }
  }

  .sectionSubtitle,
  .subtitle {
    font-family: "adrianna-extended", sans-serif;
    font-weight: 700;
    font-size: 2.1rem;
    letter-spacing: 0.03em;

    @media all and (max-width: 1600px) {
      font-size: 1.8rem;
    }

    @media all and (max-width: 1366px) {
      font-size: 1.4rem;
      letter-spacing: 0.01em;
    }

    @media all and (max-width: 700px) {
      letter-spacing: 0.02em;
      font-size: 0.8rem;
    }
  }

  .subtitle__button {
    padding: 16px;
    margin: -16px;
    display: inline-flex;
    width: auto;
    cursor: pointer;
    user-select: none;

    .subtitle {
      letter-spacing: 0.05em;
    }
  }

  p {
    font-family: "nocturne-serif", serif;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: calc(26 / 21);

    @media all and (max-width:1900px) {
      font-size: 2rem;
    }

    @media all and (max-width:1652px) {
      font-size: 1.8rem;
    }

    @media all and (max-width:1200px) {
      font-size: 1.6rem;
    }

    @media all and (max-width:800px) {
      font-size: 1.4rem;
    }

    @media all and (max-width:700px) {
      font-size: 1.6rem;
    }

    strong {
      font-weight: 600;
    }
  }

  &.white {

    p,
    .title,
    .subtitle {
      color: white;
    }
  }


  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}