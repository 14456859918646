html {
  font-size: 10px;
  font-family: "nocturne-serif", serif;
  color: black;
  background: #EFEFEF; //#333;//
  overflow: auto;
  overscroll-behavior-y: none;
  overflow-x: hidden;
  max-width: 100%;
  animation: showBgOnLoad 2s normal forwards;
  min-height: calc(100vh + 1px);
}

// @media all and (max-width: 1200px) {
//   html {
//     font-size: 9px;
//   }
// }

// @media all and (max-width: 700px) {
//   html {
//     font-size: 8px;

//   }
// }

body {
  position: relative;
  overflow: hidden;
  overscroll-behavior-y: none;
  transition-duration: 0.1s;
  animation: showBgOnLoad 2s normal forwards;
  width: 100%;
}

@media all and (max-width: 700px) {
  body {}
}

*,
:before,
:after {
  box-sizing: border-box;
  margin: 0px;
}


span {
  &.underline {
    text-decoration: underline;
  }
}

a,
.topLinkWrapper {
  text-decoration: none;
  color: black;

  &.underline {
    position: relative;
    text-decoration: underline;

    div,
    span {
      text-decoration: underline;
    }

    // &:after{
    //   position: absolute;
    //   content: "";
    //   width: 100%;
    //   bottom: 16px;
    //   height: 6px;
    //   background: black;
    //   left: 0px;
    // }
  }

  &:hover {
    color: black;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.overlay {
  transition-delay: 0s;
  transition-duration: 0.2s;
  opacity: 1;
}

.overlay.isActive {
  transition-delay: 0.0s;
  transition-duration: 0.4s;
  backdrop-filter: brightness(0.75);
}

.rellax-wrapper {
  // transition: transform 10s cubic-bezier(0, 1, .5, 1);
}

.tk-adrianna-extended {
  font-family: "adrianna-extended", sans-serif;
}

.tk-nocturne-serif {
  font-family: "nocturne-serif", serif;
}


canvas {
  opacity: 1;
}


.hideOnDesktop {
  transition-duration: 0.3s;
  opacity: 0;
  pointer-events: none;
}

.hideOnTablet {
  @media all and (max-width: 1366px) {
    display: none;
  }
}

.hideOnMobile {
  @media all and (max-width: 700px) {
    display: none;
  }
}

.showOnMobile {
  @media all and (min-width: 701px) {
    display: none;
  }

  .hover_underline {
    &:hover {
      text-decoration: underline !important;
    }

    span {
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}