.smooth-scrollbar-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  overflow: hidden;

  &.mobileMock {
    margin-left: -101vw;
    opacity: 0;
    width: 100%;
  }
}

.smooth-scrollbar-wrapper-mobile {
  top: 0px;
  position: relative;
  height: auto;
}

.parallax {
  display: flex;
}