@keyframes slideLeftOnLoad {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0vw);
  }
}

.about_section {
  padding-top: 25vh;
  padding-bottom: 25vh;

  .description {

  }
}