.description_section {
  padding-top: 128px;
  padding-bottom: 128px;
  @media all and (max-width: 1366px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media all and (max-width: 700px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .description {
    font-size: 5.1rem;
    line-height: 1.33;

    @media all and (max-width: 1366px) {
      font-size: 3.6rem;
    }

    @media all and (max-width: 700px) {
      font-size: 2.5rem;
    }
  }
}