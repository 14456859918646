@keyframes showOnLoad {
  from {
    opacity: 0;
  }
  50%{
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fluent-grid {
  position: relative;
  transition-duration: 0.3s;
  width: 100%;
  
  &.slideLeft {
    margin-left: -100vw;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
  }
  
  .fluent-grid-bg {
    animation: showOnLoad 1s normal forwards ease-in-out;
    position: fixed;
    z-index: 0;
    height: 150vh;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: -1px;
    max-width: 1652px;

    .grid {
      height: 100vh;

      .row {
        height: 100%;

        .col {
          box-shadow: inset 1px 0px 0px 0px #C9C9C9;

          &:last-child {
            margin-top: -2px;
            box-shadow: inset 0px 0px 0px 1px #C9C9C9;
          }

        }
      }

    }
  }

  .fluent-grid-content {
    z-index: 1;
    position: relative;
  }
}